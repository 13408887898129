<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconFacebook',
  mixins: [Icons],
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g id="face">
      <g>
        <path
          id="f"
          :fill="color"
          d="M112.3,179.1v-72h24.3l3.6-28.2h-27.9v-18c0-8.1,2.3-13.7,13.9-13.7l14.8,0V22.1
			c-2.6-0.3-11.4-1.1-21.7-1.1c-21.5,0-36.1,13.1-36.1,37.2v20.7H59V107h24.2v72H112.3z"
        />
      </g>
    </g>
  </svg>
</template>
